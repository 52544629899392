.spinner {
    margin: auto;
    width: 100%;
    text-align: center;

    & > div {
        width: 15px;
        height: 15px;
        background-color: #000000;
        margin-left: 0.3em;

        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
        animation-delay: -0.32s;
    }

    .bounce2 {
        animation-delay: -0.16s;
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}